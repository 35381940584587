import React, { useState, useEffect } from 'react';
import { useParams } from 'cccisd-react-router';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import { ResourceCenterWithCollection } from 'cccisd-laravel-resources/dist/Collection';
import _get from 'lodash/get';
import assignmentPlansQuery from './assignmentPlans.graphql';
import resourcesQuery from './getResources.graphql';
import RenderCertificate from '../../components/CertificateRender';
import style from './style.css';
import SELearnLogo from '../../../images/SELearn_Favicon_Gold.svg';
import IconPlus from 'cccisd-icons/plus';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconBriefcase from 'cccisd-icons/briefcase';
import IconPlay from 'cccisd-icons/play4';
import IconInfo from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import { IconInProgress } from './icons.js';

const Fortress = window.cccisd.fortress;
const AppDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const username = Fortress.user.acting.user ? Fortress.user.acting.user.username : ' ';

const MyCourses = props => {
    const [tilesData, setTilesData] = useState([]);
    const [flowProgress, setFlowProgress] = useState([]);
    const [flowData, setFlowData] = useState([]);
    const [planResources, setPlanResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const courses = AppDefs.app.courses;
    const params = useParams();

    params.completedData = {};

    let config = props.config;

    const handle = 'default';

    if (!config && AppDefs.resourceCenters && AppDefs.resourceCenters.list) {
        config = AppDefs.resourceCenters.list.find(rc => rc.id === handle);
    }

    const getAssignmentPlan = async () => {
        setLoading(true);
        const response = await client.query({
            query: assignmentPlansQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: Fortress.user.acting.id,
            },
        });

        const planData = _get(response, 'data.roles.anyRole.createdAssignmentPlanList');
        const progressData = _get(response, 'data.flows.flowProgressList');
        const flowList = _get(response, 'data.flows.flowList');

        setTilesData(planData);
        setFlowProgress(progressData);
        setFlowData(flowList);

        // get the resources used by all plans
        // or get the resources used per plan?
        if (planData.length > 0) {
            let allResourceIds = [];
            planData.forEach(plan => {
                // console.log({ plan });
                const planResourceIds = plan.sessionList.flatMap(session => session.settings.resourceIds);
                // console.log({ planResourceIds });
                allResourceIds[plan.label] = planResourceIds;
            });
            // console.log({ allResourceIds });

            let resourcesResp;
            try {
                if (allResourceIds) {
                    resourcesResp = await client.query({
                        query: resourcesQuery,
                        variables: {
                            understandingSEAssessment: allResourceIds.understandingSEAssessment
                                ? allResourceIds.understandingSEAssessment
                                : [0],
                            understandingSELDomainsSkills: allResourceIds.understandingSELDomainsSkills
                                ? allResourceIds.understandingSELDomainsSkills
                                : [0],
                            puttingDataIntoPractice: allResourceIds.puttingDataIntoPractice
                                ? allResourceIds.puttingDataIntoPractice
                                : [0],
                        },
                        fetchPolicy: 'network-only',
                    });

                    // console.log(resourcesResp);
                    let allResources = [];
                    allResources.understandingSEAssessment = resourcesResp.data.understandingSEAssessment.resourceList;
                    allResources.understandingSELDomainsSkills =
                        resourcesResp.data.understandingSELDomainsSkills.resourceList;
                    allResources.puttingDataIntoPractice = resourcesResp.data.puttingDataIntoPractice.resourceList;
                    setPlanResources(allResources);
                }
            } catch (e) {
                throw e;
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        getAssignmentPlan();
    }, []);

    const renderSurveyTitle = surveyOption => {
        // console.log('surveyOption', surveyOption);
        // console.log('flowData', flowData);
        let survey = flowData.find(flow => {
            return flow.flowHandle === surveyOption;
        });

        if (!survey) {
            return '';
        }

        let title = survey.settings.playerTemplateSettings.title
            ? survey.settings.playerTemplateSettings.title
            : survey.name;

        title = title.replace(/(<([^>]+)>)/gi, ''); // strip HTML tags

        return title;
    };

    const renderSurveyTitleAndStatusIcon = (survey, courseHandle) => {
        // console.log({ survey });
        let status = flowProgress.find(flow => {
            return flow.flowHandle === survey;
        });

        const surveyTitle = renderSurveyTitle(survey);

        if (!status) {
            return surveyTitle;
        }

        if (status.status === 'Complete') {
            // add module to the completed data
            params.completedData[courseHandle].push({
                title: surveyTitle,
                survey,
            });

            return (
                <div>
                    {surveyTitle}
                    <span style={{ marginLeft: '.5em', color: '#10C700' }}>
                        <Tooltip title="Survey has been completed" placement="bottom">
                            <IconCheck /> Complete
                        </Tooltip>
                    </span>
                </div>
            );
        }

        // console.log(params.completedData);
        if (status.status === 'In Progress') {
            return (
                <div>
                    {surveyTitle}
                    <span style={{ marginLeft: '.5em', color: '#d68f28' }}>
                        <Tooltip title="Survey is in progress" placement="bottom">
                            <IconInProgress /> In Progress
                        </Tooltip>
                    </span>
                </div>
            );
        }
    };

    const getSelectedSurveys = course => {
        // has any course been selected
        if (!tilesData) {
            return [];
        }

        // console.log({ course });
        // console.log({ tilesData });
        let thisCourseData =
            tilesData &&
            tilesData.find(tile => {
                return tile.label.includes(course.handle);
            });

        // console.log({ thisCourseData });
        if (!thisCourseData) {
            return [];
        }

        let sessionList = thisCourseData.sessionList;
        // console.log({ sessionList });
        if (!sessionList) {
            return [];
        }

        // get the surveys selected for this course if any
        let thisCourse =
            sessionList &&
            sessionList.find(session => {
                return session.settings.handle.includes(course.handle);
            });

        // console.log({ thisCourse });
        return thisCourse ? thisCourse.settings.options : [];
    };

    const renderCertificateButton = (courseHandle, courseLabel) => {
        // console.log('render Certificate');
        // console.log(params.completedData[courseHandle]);
        return (
            <div>
                {params.completedData[courseHandle].length > 0 ? (
                    <Modal
                        trigger={
                            <Tooltip title="Displays the certificate for the Learning Objective. It will list all of the courses under that Learning Objective completed by the user.">
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    style={{ backgroundColor: '#4670B2' }}
                                >
                                    Certificate
                                </button>
                            </Tooltip>
                        }
                        title={`${courseLabel} Certificate`}
                        size="large"
                        contentStyle={{ width: '1000px' }}
                        headerStyle={{ backgroundColor: '#d68f28', color: '#fff' }}
                        bodyStyle={{ backgroundColor: '#edf2f7' }}
                    >
                        <RenderCertificate
                            {...props}
                            courseLabel={courseLabel}
                            courseHandle={courseHandle}
                            moduleList={params.completedData[courseHandle]}
                            resources={planResources[courseHandle]}
                        />
                    </Modal>
                ) : (
                    <button type="button" className="btn btn-primary" disabled>
                        Certificate
                    </button>
                )}
            </div>
        );
    };

    const renderResourceCollection = (course, action) => {
        // console.log('render resource collection');
        // console.log({ course, action });
        // console.log({ tilesData });

        let thisCourseData =
            tilesData &&
            tilesData.find(tile => {
                return tile.label.includes(course.handle);
            });
        // console.log({ thisCourseData });
        const courseResources = planResources[course.handle];
        // console.log({ courseResources });

        return (
            <div className={style.lastRowNoGap}>
                {action === 'add' ? (
                    <Modal
                        // ref={creationModalRef}
                        trigger={
                            <button type="button" className={style.pdButton}>
                                <IconBriefcase /> Add Modules
                            </button>
                        }
                        title={`${course.label} Course Modules`}
                        size="99vw"
                        headerStyle={{ backgroundColor: '#d68f28', color: '#fff' }}
                        bodyStyle={{ backgroundColor: '#edf2f7' }}
                    >
                        <ResourceCenterWithCollection
                            collectionUserList={[{ id: Fortress.user.acting.id }]}
                            handle={course.rcTab}
                            logo={SELearnLogo}
                            onSubmit={onSubmit}
                            collectionName="Courses"
                            renderResourceTiles={renderResourceTiles}
                            collectionBuckets={[course.label]}
                            disableTimepoints
                            collectionBucketsFormTitle={`Courses for ${course.label}`}
                            planName={username}
                            styleConfig={config.displayOptions}
                            assessmentLibraryLabel={course.label}
                            baselineSessionLabel="Course"
                            assessmentLabel="course"
                            surveyLabel="module"
                        />
                    </Modal>
                ) : (
                    <Modal
                        // ref={updateModalRef}
                        trigger={
                            <button type="button" className={style.addMore}>
                                <IconPlus /> Add/Remove Modules
                            </button>
                        }
                        title={`${course.label} Course Modules`}
                        size="99vw"
                        headerStyle={{ backgroundColor: '#d68f28', color: '#fff' }}
                        bodyStyle={{ backgroundColor: '#edf2f7' }}
                    >
                        <ResourceCenterWithCollection
                            collectionUserList={[{ id: Fortress.user.acting.id }]}
                            handle={course.rcTab}
                            logo={SELearnLogo}
                            onSubmit={onSubmitEdit}
                            collectionName="Courses"
                            renderResourceTiles={renderResourceTiles}
                            collectionBuckets={[course.label]}
                            disableTimepoints
                            collectionBucketsFormTitle={`Courses for ${course.label}`}
                            planName={username}
                            editCollection="add"
                            selectedPlan={thisCourseData}
                            planResources={formatResourcesForCollection(courseResources)}
                            styleConfig={config.displayOptions}
                            assessmentLibraryLabel={course.label}
                            baselineSessionLabel="Course"
                            assessmentLabel="course"
                            surveyLabel="module"
                        />
                    </Modal>
                )}
            </div>
        );
    };
    // adding a new plan for the user
    const onSubmit = async vals => {
        vals.sessions[0].sessionType = 'offset';
        vals.sessions[0].offset = 0;
        vals.sessions[0].opensAt = null;
        vals.sessions[0].closesAt = null;

        if (vals.sessions[0].settings.reporter === 'Understanding SEL') {
            vals.sessions[0].settings.handle = 'understandingSEAssessment';
        }
        if (vals.sessions[0].settings.reporter === 'Understanding SEL Domains and Skills') {
            vals.sessions[0].settings.handle = 'understandingSELDomainsSkills';
        }
        if (vals.sessions[0].settings.reporter === 'Putting Data Into Practice') {
            vals.sessions[0].settings.handle = 'puttingDataIntoPractice';
        }

        let planData = {
            creatingPawnId: Fortress.user.acting.id,
            label: vals.sessions[0].settings.handle,
            pawnIds: vals.respondents.map(r => r.id),
            sessions: vals.sessions,
        };

        // console.log({ planData });

        await axios.post(Boilerplate.route('api.assignment.plan.create'), planData).then(response => {
            if (response.data.status === 'success') {
                notification({
                    message: 'Module(s) have been added to your course.',
                    type: 'success',
                    duration: 8000,
                });
                window.location.href = Boilerplate.url('/myCourses');
            } else {
                notification({
                    message: 'Error adding module(s) to your course.',
                    data: response && response.data && response.data.errors,
                    duration: -1,
                    type: 'danger',
                });
            }
        });
    };

    //  updating existing plan
    const onSubmitEdit = async vals => {
        vals.sessions[0].sessionType = 'offset';
        vals.sessions[0].offset = 0;
        vals.sessions[0].opensAt = null;
        vals.sessions[0].closesAt = null;

        if (vals.sessions[0].settings.reporter === 'Understanding SEL') {
            vals.sessions[0].settings.handle = 'understandingSEAssessment';
        }
        if (vals.sessions[0].settings.reporter === 'Understanding SEL Domains and Skills') {
            vals.sessions[0].settings.handle = 'understandingSELDomainsSkills';
        }
        if (vals.sessions[0].settings.reporter === 'Putting Data Into Practice') {
            vals.sessions[0].settings.handle = 'puttingDataIntoPractice';
        }

        let planData = {
            creatingPawnId: Fortress.user.acting.id,
            label: vals.sessions[0].settings.handle,
            pawnIds: vals.respondents.map(r => r.id),
            sessions: vals.sessions,
        };
        // console.log({ planData });
        // console.log(vals.planId);

        await axios
            .put(
                Boilerplate.route('api.assignment.plan.update', {
                    plan: vals.planId,
                }),
                planData
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notification({
                        message: 'Course(s) have been updated.',
                        type: 'success',
                        duration: 8000,
                    });
                    window.location.href = Boilerplate.url('/myCourses');
                } else {
                    notification({
                        message: 'Error updating your course(s).',
                        data: response && response.data && response.data.errors,
                        duration: -1,
                        type: 'danger',
                    });
                }
            });
    };

    const renderResourceTiles = (data, renderDefault) => {
        return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
    };

    const getFlowLanguages = metadata => {
        let languages = new Set();
        if (metadata.surveyInfo && metadata.surveyInfo.flowList) {
            metadata.surveyInfo.flowList.forEach(flow => {
                languages.add(flow.language);
            });
        }

        return [...languages];
    };

    const formatResourcesForCollection = resourceList => {
        // console.log(resourceList);
        const formattedResources = resourceList.map(r => {
            const tags = r.tagList.reduce((acc, cur) => ({ ...acc, [cur.tagId]: cur.handle }), {});
            return {
                id: r.resourceId,
                label: r.label,
                description: r.description,
                tags,
                languages: getFlowLanguages(JSON.parse(r.metadata)),
                resource: { published_metadata: r.metadata },
            };
        });
        // console.log({ formattedResources });
        return formattedResources;
    };
    if (loading) {
        return <Loader loading />;
    }

    // console.log({ tilesData });
    // console.log({ courses });

    return (
        <div className={style.tileContainer}>
            <div>
                <h1>My Courses</h1>
            </div>
            <div className={style.tiles}>
                <div className={style.tiles}>
                    {courses.map(course => {
                        params.completedData[course.handle] = [];

                        // get the surveys selected for the learning objective course
                        const selectedSurveys = getSelectedSurveys(course);
                        // console.log(selectedSurveys);
                        return (
                            <div key={course.handle} className={style.tile}>
                                <h3 className={style.courseTitle}>{course.label}</h3>
                                <p className={style.courseDesc}>
                                    <strong>{course.description}</strong>
                                </p>
                                <ol className={style.courseSurveys}>
                                    {selectedSurveys.map((survey, index) => (
                                        <li key={index} className={style.courseSurvey}>
                                            {/* {renderSurveyTitle(survey)} {renderStatusIcon(survey, course.handle)} */}
                                            {renderSurveyTitleAndStatusIcon(survey, course.handle)}
                                        </li>
                                    ))}
                                </ol>

                                {selectedSurveys.length > 0 ? (
                                    <div className={style.tileButtons}>
                                        <Modal
                                            trigger={
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    style={{
                                                        backgroundColor: '#4670B2',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    <IconPlay />
                                                    Start/Resume Course
                                                </button>
                                            }
                                            title={course.label}
                                            size="large"
                                            contentStyle={{ width: '1200px' }}
                                            headerStyle={{
                                                backgroundColor: '#d68f28',
                                                color: '#fff',
                                            }}
                                            bodyStyle={{ backgroundColor: '#edf2f7' }}
                                            afterClose={getAssignmentPlan}
                                        >
                                            <div>
                                                <DeploymentPlayer
                                                    deploymentHandle={course.label}
                                                    pawnId={Fortress.user.acting.id}
                                                    pawnHash={Fortress.user.acting.random_hash}
                                                    disableLayout
                                                    assignmentOptions={selectedSurveys}
                                                />
                                            </div>
                                        </Modal>
                                    </div>
                                ) : (
                                    <div className={style.infoText}>
                                        <IconInfo />{' '}
                                        <i>
                                            You haven’t selected any modules for your personalized course(s). Go to the
                                            PD Center to browse available modules. To add modules to your course, click
                                            "Add Modules" below.
                                        </i>
                                    </div>
                                )}

                                {selectedSurveys.length > 0 ? (
                                    <div className={style.lastRowNoGap}>
                                        {renderResourceCollection(course, 'update')}

                                        {renderCertificateButton(course.handle, course.label)}
                                    </div>
                                ) : (
                                    <div className={style.lastRowNoGap}>
                                        {renderResourceCollection(course, 'add')}

                                        {renderCertificateButton(course.handle, course.label)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MyCourses;
