import React, { useState, useEffect } from 'react';
import { ResourceCenterWithCollection } from 'cccisd-laravel-resources/dist/Collection';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
// import objectiveDeploymentQuery from './objectiveDeployment.graphql';
import assignmentProgressQuery from './assignmentProgress.graphql';
import resourcesQuery from './getResources.graphql';
import SELearnLogo from '../../../images/SELearn_Favicon_Gold.svg';
import style from './style.css';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;
const AppDefs = window.cccisd.appDefs;
const username = Fortress.user.acting.user ? Fortress.user.acting.user.username : ' ';

// there is only 1 plan per user (keep editing the same plan)
// there is a session per each objective

const ProfDevCenter = props => {
    const [assignmentPlan, setAssignmentPlan] = useState([]);
    const [planResources, setPlanResources] = useState([]);
    const [loading, setLoading] = useState(true);

    let config = props.config;

    const handle = 'default';

    if (!config && AppDefs.resourceCenters && AppDefs.resourceCenters.list) {
        config = AppDefs.resourceCenters.list.find(rc => rc.id === handle);
    }

    useEffect(() => {
        setLoading(true);
        const getAssignmentPlan = async () => {
            const response = await client.query({
                query: assignmentProgressQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId: Fortress.user.acting.id,
                },
            });

            return response;
        };
        getAssignmentPlan().then(async data => {
            const existingPlan = _get(data, 'data.roles.anyRole.createdAssignmentPlan');

            setAssignmentPlan(existingPlan);

            if (existingPlan.assignmentPlanId) {
                const resourceIds = _uniq(existingPlan.sessionList.map(session => session.settings.resourceIds).flat());
                let resourcesResp;
                try {
                    if (resourceIds && resourceIds.length > 0) {
                        resourcesResp = await client.query({
                            query: resourcesQuery,
                            variables: {
                                resourceIds,
                            },
                            fetchPolicy: 'network-only',
                        });
                        setPlanResources(resourcesResp.data.resources.resourceList);
                    }
                } catch (e) {
                    throw e;
                }
            }

            setLoading(false);
        });
    }, []);

    // adding a new plan for the user
    const onSubmit = async vals => {
        vals.sessions.forEach(session => {
            session.sessionType = 'offset';
            session.offset = 0;
            if (session.settings.reporter === 'Understanding SEL') {
                session.settings.handle = 'understandingSEAssessment';
            }
            if (session.settings.reporter === 'Understanding SEL Domains and Skills') {
                session.settings.handle = 'understandingSELDomainsSkills';
            }
            if (session.settings.reporter === 'Putting Data Into Practice') {
                session.settings.handle = 'puttingDataIntoPractice';
            }
        });
        let planData = {
            creatingPawnId: Fortress.user.acting.id,
            label: username,
            pawnIds: vals.respondents.map(r => r.id),
            sessions: vals.sessions,
        };

        await axios.post(Boilerplate.route('api.assignment.plan.create'), planData).then(response => {
            if (response.data.status === 'success') {
                notification({
                    message: 'Module(s) have been added to your course(s).',
                    type: 'success',
                    duration: 8000,
                });
                window.location.href = Boilerplate.url('/myCourses');
            } else {
                notification({
                    message: 'Error adding module(s) to your course(s).',
                    data: response && response.data && response.data.errors,
                    duration: -1,
                    type: 'danger',
                });
            }
        });
    };

    //  updating existing plan
    const onSubmitEdit = async vals => {
        vals.sessions.forEach(session => {
            session.sessionType = 'offset';
            session.offset = 0;
            if (session.settings.reporter === 'Understanding SEL') {
                session.settings.handle = 'understandingSEAssessment';
            }
            if (session.settings.reporter === 'Understanding SEL Domains and Skills') {
                session.settings.handle = 'understandingSELDomainsSkills';
            }
            if (session.settings.reporter === 'Putting Data Into Practice') {
                session.settings.handle = 'puttingDataIntoPractice';
            }
        });

        let planData = {
            creatingPawnId: Fortress.user.acting.id,
            label: username,
            pawnIds: vals.respondents.map(r => r.id),
            sessions: vals.sessions,
        };

        await axios
            .put(
                Boilerplate.route('api.assignment.plan.update', {
                    plan: assignmentPlan.assignmentPlanId,
                }),
                planData
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notification({
                        message: 'Course(s) have been updated.',
                        type: 'success',
                        duration: 8000,
                    });
                    window.location.href = Boilerplate.url('/myCourses');
                } else {
                    notification({
                        message: 'Error updating your course(s).',
                        data: response && response.data && response.data.errors,
                        duration: -1,
                        type: 'danger',
                    });
                }
            });
    };

    const renderResourceTiles = (data, renderDefault) => {
        return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
    };

    const getFlowLanguages = metadata => {
        let languages = new Set();
        if (metadata.surveyInfo && metadata.surveyInfo.flowList) {
            metadata.surveyInfo.flowList.forEach(flow => {
                languages.add(flow.language);
            });
        }

        return [...languages];
    };

    const formatResourcesForCollection = resourceList => {
        const formattedResources = resourceList.map(r => {
            const tags = r.tagList.reduce((acc, cur) => ({ ...acc, [cur.tagId]: cur.handle }), {});
            return {
                id: r.resourceId,
                label: r.label,
                description: r.description,
                tags,
                languages: getFlowLanguages(JSON.parse(r.metadata)),
                resource: { published_metadata: r.metadata },
            };
        });

        return formattedResources;
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div style={{ width: '100%', fontWeight: '300' }}>
            {assignmentPlan.assignmentPlanId ? (
                <ResourceCenterWithCollection
                    collectionUserList={[{ id: Fortress.user.acting.id }]}
                    handle="default"
                    logo={SELearnLogo}
                    onSubmit={onSubmitEdit}
                    collectionName="Courses"
                    renderResourceTiles={renderResourceTiles}
                    collectionBuckets={[
                        'Understanding SEL',
                        'Understanding SEL Domains and Skills',
                        'Putting Data Into Practice',
                    ]}
                    disableTimepoints
                    collectionBucketsFormTitle="Courses by Learning Objective"
                    planName={username}
                    editCollection="add"
                    selectedPlan={assignmentPlan}
                    planResources={formatResourcesForCollection(planResources)}
                    styleConfig={config.displayOptions}
                    assessmentLibraryLabel="PD Center"
                    baselineSessionLabel="Course"
                    assessmentLabel="course"
                    surveyLabel="module"
                />
            ) : (
                <ResourceCenterWithCollection
                    collectionUserList={[{ id: Fortress.user.acting.id }]}
                    handle="default"
                    logo={SELearnLogo}
                    onSubmit={onSubmit}
                    collectionName="Courses"
                    renderResourceTiles={renderResourceTiles}
                    collectionBuckets={[
                        'Understanding SEL',
                        'Understanding SEL Domains and Skills',
                        'Putting Data Into Practice',
                    ]}
                    disableTimepoints
                    collectionBucketsFormTitle="Courses by Learning Objective"
                    planName={username}
                    styleConfig={config.displayOptions}
                    assessmentLibraryLabel="PD Center"
                    baselineSessionLabel="Course"
                    assessmentLabel="course"
                    surveyLabel="module"
                />
            )}
        </div>
    );
};

export default ProfDevCenter;
