import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];
    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    return (
        <Header
            containerClassName="container-fluid"
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={primaryNavLinks}
            secondaryNavLinks="header"
            showAlertAction={false}
            showHomeAction={false}
            {...(noLogoLink ? { logoLink: null } : {})}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
