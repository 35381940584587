import React from 'react';
import mockup from './Admin_Dashboard_Mockup.png';
import style from './style.css';
import IconInfo from 'cccisd-icons/info2';

const AdminDashboard = () => {
    return (
        <div>
            <div className={style.infoText}>
                <IconInfo />{' '}
                <i>
                    Please note that this Admin Dashboard image is a mockup. Full functionality will be available at a
                    later stage of the project.
                </i>
            </div>
            <div>
                <img src={mockup} style={{ maxWidth: '100%' }} alt="Example of Admin Dashboard" />
            </div>
        </div>
    );
};

export default AdminDashboard;
