import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from 'cccisd-apollo';
import { ResourceCenter } from 'cccisd-laravel-resources';
import rcFilterQuery from './rcFilterQuery.graphql';

const SELCenter = () => {
    // Get the current URL object
    const location = useLocation();
    const Fortress = window.cccisd.fortress;

    useEffect(() => {
        const parseParams = () => {
            // Get the search string from the URL
            const searchParams = new URLSearchParams(location.search);
            const filterRC = searchParams.get('filterRC');

            // console.log('filter param:', filterRC);

            async function fetchData() {
                try {
                    // use the last response completed
                    const response = await client.query({
                        query: rcFilterQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            assignmentId: 1,
                            pawnId: Fortress.user.acting.id,
                        },
                    });
                    // console.log(response.data.flows.assignmentProgressList[0]);
                    generateFilters(response.data.flows.assignmentProgressList[0]);
                } catch (error) {
                    console.log(error);
                }
            }

            if (filterRC) {
                fetchData();
            }
        };

        // Call the parseParams function on component mount
        parseParams();
    }, [location.search]); // Re-run the effect when the search string changes

    const generateFilters = data => {
        // console.log('data', data);
        // build the URL for the filtered RC
        let newPath = '/selCenter/?tab=SEL%20Center&tag=';
        let selectedTags = [];

        const keys = Object.keys(data.variables);

        // get all the metrics/tags that were selected
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = data.variables[key];
            // console.log({ key, value });
            if (value === '1') {
                selectedTags.push(key);
            }

            // add the grade level and format tags
            if (key === 'Grade_Level') {
                selectedTags.push(data.variables.Grade_Level);
            }

            if (key === 'Format') {
                selectedTags.push(data.variables.Format);
            }
        }

        // console.log('selectedTags', selectedTags);
        const selectedTagString = selectedTags.join('&tag=');

        newPath += selectedTagString;

        // may not need this if internal RC tag also has '_' instead of spaces
        // internal RC tag string name should match variable name in survey
        // e.g.
        // 1. in RC Self_Management (label = Self - Management, internal = Self_Management)
        // 2. in Quest Code, value Self-Management has variable name Self_Management
        // newPath = newPath.replaceAll('_', '%20');
        // for MC questions, used the value or the coded value (e.g. grade and format)

        // route to filtered RC
        window.location.href = newPath;
    };

    return (
        // check if need to pass in match params for sharing resources
        <div>
            <ResourceCenter handle="selCenter" />
        </div>
    );
};

export default SELCenter;
