import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Loader from 'cccisd-loader';
import { Page, Report, BrowserOnly, PrintOnly } from 'cccisd-laravel-report';
import IconCheckBox from 'cccisd-icons/checkbox-checked';

import style from './style.css';

const Fortress = window.cccisd.fortress;

export const DATE_FORMAT = 'MMMM D, YYYY';

const CertificateRender = props => {
    const [formattedDate, setFormattedDate] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getCertificateDateAndUser() {
            const actingUser = Fortress.user.acting.user || {};
            const altName = actingUser.full_name || actingUser.username;
            setName(altName);
            setFormattedDate(moment().format(DATE_FORMAT));
        }

        async function loadData() {
            getCertificateDateAndUser();
            setLoading(false);
        }

        loadData();
    }, []);

    /* /////////////////////////////////////////////////////////////////////////
        // RENDER-RELATED /////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////// */

    function renderContent({ viewType = 'browser' }) {
        const containerStyle =
            viewType === 'browser' ? style.certificateContainerBrowser : style.certificateContainerPrint;
        return (
            <div className={containerStyle}>
                <div className={style.certificate}>
                    <div className={style.passDate}>{formattedDate}</div>
                    <div className={style.certificateContent}>
                        <div className={style.name}>{name}</div>
                        <div className={style.courseName}>
                            <span>
                                <b>Learning Objective: </b>
                                {props.courseLabel}
                            </span>
                        </div>
                        <div>
                            {props.resources && renderDuration()} <span>minutes of continuing education</span>
                        </div>
                        {props.moduleList && renderModules()}
                    </div>
                </div>
            </div>
        );
    }

    const getSurveyDuration = metadata => {
        let duration;
        duration = metadata.duration ? metadata.duration : 0;

        if (duration === 0) {
            return 0;
        }

        const numericValue = parseFloat(duration.match(/\d+(\.\d+)?/)[0]);

        return numericValue;
    };

    function renderDuration() {
        console.log(props.moduleList);
        console.log(props.resources);

        let completedDuration = [];

        props.moduleList.forEach(completedItem => {
            console.log({ completedItem });
            // Find corresponding resource
            props.resources.find(resource => {
                console.log({ resource });
                if (resource.metadata) {
                    const metadata = JSON.parse(resource.metadata);
                    console.log({ metadata });
                    if (metadata.surveyInfo && metadata.surveyInfo.surveyHandle === completedItem.survey) {
                        completedDuration.push(getSurveyDuration(metadata));
                    }
                }
                return false;
            });
        });

        console.log(completedDuration);
        const sum = completedDuration.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        // console.log(sum);
        return sum.toFixed(2);
    }

    function renderModules() {
        return (
            <div className={style.moduleContainer}>
                <ul>
                    {props.moduleList.map((item, index) => {
                        if (index % 2 === 0) {
                            return (
                                <div className="row" key={index}>
                                    <div className="col-md-6">
                                        <li className={style.moduleLeft}>
                                            <IconCheckBox /> <span className={style.moduleTitle}>{item.title}</span>
                                        </li>
                                    </div>
                                    {index + 1 < props.moduleList.length && (
                                        <div className="col-md-6">
                                            <li className={style.moduleRight}>
                                                <IconCheckBox />{' '}
                                                <span className={style.moduleTitle}>
                                                    {props.moduleList[index + 1].title}
                                                </span>
                                            </li>
                                        </div>
                                    )}
                                </div>
                            );
                        }
                        return null; // Skip odd index items
                    })}
                </ul>
            </div>
        );
    }

    /* /////////////////////////////////////////////////////////////////////////
        // RENDER /////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////// */

    if (loading) {
        return <Loader loading />;
    }

    const downloadFilename = `Certificate_${props.courseLabel}`;

    return (
        <>
            <Report downloadFilename={downloadFilename}>
                <Page key={props.courseLabel}>
                    <BrowserOnly>{renderContent({ viewType: 'browser' })}</BrowserOnly>
                    <PrintOnly>{renderContent({ viewType: 'print' })}</PrintOnly>
                </Page>
            </Report>
        </>
    );
};

CertificateRender.propTypes = {
    courseLabel: PropTypes.string,
    courseHandle: PropTypes.string,
    moduleList: PropTypes.object,
    resources: PropTypes.object,
};

export default CertificateRender;
