import React from 'react';

// icons used by the Resource Center Collection view

export const IconComplete = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path xmlns="http://www.w3.org/2000/svg" fill="#154988" d="M8 0a8 8 0 100 16A8 8 0 008 0z" />
    </svg>
);

export const IconInProgress = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            xmlns="http://www.w3.org/2000/svg"
            fill="#D68F27"
            fillRule="evenodd"
            d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 1.5v13a6.5 6.5 0 100-13z"
            clipRule="evenodd"
            transform="rotate(270 8 8)"
        />
    </svg>
);
