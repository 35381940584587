import React, { useState } from 'react';
import style from './style.css';
import { ResourceCenter } from 'cccisd-laravel-resources';
import { useParams } from 'react-router';
import RCBannerImg from './RC_Banner-smaller.png';
const tabs = [
    { handle: 'Understanding_SE_Assessment', name: 'Understanding SEL', tab: 'tab' },
    { handle: 'Understanding_SEL_Domains_And_Skills', name: 'Understanding SEL Domains and Skills', tab: 'tab2' },
    { handle: 'Putting_Data_Into_Practice', name: 'Putting Data Into Practice', tab: 'tab1' },
];

const RevisedPD = props => {
    const params = useParams();
    const match = tabs.find(item => item.handle === params?.tab);
    const [tab, setTab] = useState(match ? match.handle : 'Understanding_SE_Assessment');

    // console.log(params);

    const renderTabs = () => {
        return (
            <>
                {tabs.map((t, i) => (
                    <div
                        key={i}
                        className={style.tab}
                        style={
                            tab === t.handle ? { backgroundColor: '#4670B2' } : { backgroundColor: 'rgb(214, 143, 40)' }
                        }
                        onClick={() => {
                            setTab(t.handle);
                        }}
                    >
                        {t.name}
                    </div>
                ))}
            </>
        );
    };

    const renderResourceCenter = () => {
        return (
            <ResourceCenter
                handle={tab}
                match={{ params: { tab: match ? match.handle : tab, id: params?.id ? params.id : '' } }}
            />
        );
    };

    const renderHeader = () => {
        // const title = tabs.find(item => item.handle === tab).name;
        // get from json!
        const welcomeText =
            "Welcome to the Professional Development Resource Center! Here you'll find resources designed to strengthen your understanding of social-emotional learning and how it relates to your role as an educator. Explore videos and fact sheets about SEL assessment, the Collaborative for Academic, Social, and Emotional Learning's (CASEL) five social and emotional competence domains, and how to apply SEL assessment to your work in the classroom.";
        return (
            <>
                <h1>PD Center</h1>

                <div className={style.infoBar}>
                    <div className={style.welcome}>
                        <div className={style.leftSide}>
                            <strong>{welcomeText}</strong>
                        </div>
                        <div className={style.rightSide}>
                            <img src={RCBannerImg} alt="bannerimg" />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div>
            <div>{renderHeader()}</div>
            <div className={style.tabWrapper}>{renderTabs()}</div>
            <div>{renderResourceCenter()}</div>
        </div>
    );
};

export default RevisedPD;
